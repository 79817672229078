import React, {useEffect, useState} from "react";
import style from './layout.module.sass'
import CheckoutSectionComponent from "./components/checkout_section";
import {useDispatch, useSelector} from "react-redux";
import PaymentComponent from "./components/payment";
import {Elements} from "@stripe/react-stripe-js";
import EmptyComponent from "./components/empty";
import {loadStripe} from "@stripe/stripe-js";
import ScrollIntoView from "../../components/scroll_into_view";
import {useHistory} from "react-router-dom";
import PaymentOptions from "./components/payment_options";
import {CreateOrder} from "../../redux/order/actions";


const CheckoutPage = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const cart = useSelector(state => state.cart)
    const config = useSelector(state => state.config)

    const [billingAddress, setBillingAddress] = useState(false)
    const [paymentOption, setPaymentOption] = useState({
        option: 'online',
        value: 1
    })

    const [billingAddressData, setBillingAddressData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        city: '',
        address: '',
        zip: ''
    })

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        document.title = 'Checkout'
        const obj = localStorage.getItem('billingAddress') || "{}"
        if (obj !== JSON.stringify({})) {
            const obj_json = JSON.parse(obj)
            setBillingAddressData({
                firstName: obj_json.firstName,
                lastName: obj_json.lastName,
                email: obj_json.email,
                phone: obj_json.phone,
                city: obj_json.city,
                address: obj_json.address,
                zip: obj_json.zip
            })
        }
    }, [])

    const submit = () => {
        let billingAddressData = localStorage.getItem('billingAddress') || "{}"
        let billingAddressDataJson = {}
        if (billingAddressData !== JSON.stringify({})) {
            billingAddressDataJson = JSON.parse(billingAddressData)
        }


        const orderData = {
            products: cart.data.products,
            billing_address: billingAddressDataJson,
            total_products_price: cart.data.total_products_price,
            shipping_price: cart.data.shipping,
            total_price: cart.data.total_price,
            currency: config.data.payment.currency,
            payment: {
                card: false
            }
        }
        dispatch(CreateOrder(orderData, history))
    }

    return (
        <ScrollIntoView>
            <div className={style.box}>
                <div className={style.boxContent}>

                    <section>
                        <div className={style.checkoutTitle}>
                            <h1>Checkout</h1>
                        </div>
                    </section>

                    {
                        cart.success && cart.data.products.length > 0 && config.loaded &&
                        <div>
                            <section>
                                <CheckoutSectionComponent setBillingAddress={setBillingAddress}/>
                            </section>

                            {
                                billingAddress
                                &&
                                <section>
                                    <PaymentOptions paymentOption={paymentOption} setPaymentOption={setPaymentOption}/>
                                </section>
                            }

                            {
                                paymentOption.value === 1
                                &&
                                <section>
                                    <Elements stripe={loadStripe(config.data.payment.stripe_public)}>
                                        {
                                            billingAddress && <PaymentComponent price={cart.data.total_price}/>
                                        }
                                    </Elements>
                                </section>
                            }

                            {
                                paymentOption.value === 2 && billingAddress
                                &&
                                <section>
                                    <button onClick={() => submit()} className="button button--full">
                                        {loading ? 'Submitting ...' : 'Submit Order'}
                                    </button>
                                </section>
                            }

                        </div>
                    }

                    {
                        cart.success && cart.data.products.length < 1 && <EmptyComponent/>
                    }
                </div>
            </div>
        </ScrollIntoView>
    )
}

export default CheckoutPage